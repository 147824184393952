<template>
    <div class="col-sm-12">
      <div class="rounded shadow-sm">
        <div class="osahan-privacy bg-white rounded shadow-sm p-4">
          <div class="row px-5 py-5 p-0">
            <div class="col-sm-12">
                <p>You have succesfully booked an appointment</p>
                <!-- <div id="pdf-content">
                            <table class="table self-table">
                                <thead></thead>
                                <tbody style="border: 1px solid black;">
                                    <tr>
                                        <td>Patient Name</td>
                                        <td> {{ getSuccessResponse.patient_info.fname {{ getSuccessResponse.patient_info.mname {{ getSuccessResponse.patient_info.lname</td>
                                    </tr>
                                    <tr>
                                        <td>Appointment Date</td>
                                        <td>{{ getSuccessResponse.appointment_date</td>
                                    </tr>
                                    <tr>
                                        <td>Appointment Time</td>
                                        <td>{{ getSuccessResponse.appointment_time</td>
                                    </tr>
                                </tbody>
                            </table>
                </div> -->
                <div class="appointment-slip">

                    <div class="slip-body" id="printSummary">
                        <div class="row slip-no-margin slip-heading">
                            <p style="text-align: center;margin-bottom:4px;font-size:16px;"><strong>Surkhet Hospital</strong></p>
                            <p style="text-align: center;margin:1px;;font-size:13px;"><strong>Province Hospital</strong></p>
                            <p style="text-align: center;"><strong>Appointment Slip</strong></p>
                        </div>
                        <div class="row slip-body-main">
                            <table style="width:100%;float:left;">
                                <tr>
                                    <!-- <td ></td> -->
                                    <td colspan="2" v-if="getSuccessResponse.payment_status == 'Completed'">Recepit No. :
                                        <strong >{{getSuccessResponse.receipt_no}}</strong>
                                    </td>
                                    <td v-else>Recepit No. :<strong > N/A </strong></td>
                                </tr>
                            </table>
                            <table class="slip-body-table-2" >
                                <thead>
                                    <th colspan="3"></th>
                                    <th colspan="2"><strong></strong></th>
                                    
                                </thead>
                                <tbody style="line-height: 36px;">
                                    
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Patient Name : <strong>{{ getSuccessResponse.patient_info.fname }} {{ getSuccessResponse.patient_info.mname }} {{ getSuccessResponse.patient_info.lname}}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Age : <strong>{{ getSuccessResponse.patient_info.age }} {{ getSuccessResponse.patient_info.age_type }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Address : <strong>{{ getSuccessResponse.patient_info.address }}</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Appointment Date : <strong>{{getSuccessResponse.appointment_date}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Appointment Time : <strong>{{getSuccessResponse.appointment_time}}</strong></td>
                                    </tr>
    
                                    
                                    <!-- <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Consultant: <strong>.doctor_name</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Speciality: <strong>.speciality_name</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Current Date: <strong>@{{ current_date</strong></td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Country Applied For: &nbsp;
                                            <strong>.country</strong>
                                        </td>
                                    </tr> -->
                                    <!-- {{-- <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Intake Date: &nbsp; <strong>.intake_date</strong>
                                        </td>
                                    </tr> --}} -->
    
                                </tbody>
                            </table>
                            <table class="slip-body-table-3" >
                                <thead>
                                    <th colspan="2"></th>
                                    <th colspan="2"></th>
                                </thead>
                                <tbody style="line-height: 36px;">
                                    <!-- <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Txn ID :
                                            <strong>{{getSuccessResponse.transaction_id}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Payment Status :
                                            <strong >{{getSuccessResponse.payment_status}}</strong>
                                        </td>
                                    </tr>
                                   
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2">Department :
                                            <strong >{{department[0].department}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2"></td>
                                        <td colspan="2" v-if="getSuccessResponse.payment_status == 'Completed'">Total Amount :
                                            <strong >Rs. {{getSuccessResponse.total_amount}}</strong>
                                        </td>
                                        <td v-else>Total Amount :
                                            <strong > N/A</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                           
                        </div>
                        <p v-if="getSuccessResponse.payment_status == 'Completed'">Payment done from Khalti</p>
                </div>
</div>
                

      </div>
            <div class="col-sm-12">
               
                <button @click="generatePDF" type="button" class="btn btn-primary" >Print</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
    import { onMounted, computed } from 'vue'
    import { storeToRefs } from 'pinia'
    import { usePayment } from "@/core/modules/payment"
    import { useSelfAppointment } from '@/core/modules/self/appointment';
    const { getDepartmentListData } = storeToRefs(useSelfAppointment());
    const store = useSelfAppointment();
    // import html2pdf from "html2pdf.js";
    onMounted(async () => {
        let opd_id = window.localStorage.getItem('opd_id');
        if(opd_id) {
            window.localStorage.removeItem('opd_id')
        }
        let schedule_id = window.localStorage.getItem('schedule_id');
        if(schedule_id) {
            window.localStorage.removeItem('schedule_id')
        }
        let rate = window.localStorage.getItem('rate');
        if(rate) {
            window.localStorage.removeItem('rate')
        }
        let bookpay = window.localStorage.getItem('bookpay');
        if(bookpay) {
            window.localStorage.removeItem('bookpay')
        }
        await store.getDepartmentList();
    })

    const { getSuccessResponse } = storeToRefs(usePayment());
    function generatePDF() {
        var mywindow = window.open();
                        mywindow.document.write('<html><head><title>' + document.title + '</title>');
                        mywindow.document.write('<style type="text/css">');
                        mywindow.document.write(
                            'body { font-size: 16px; font-family: sans-serif; margin:40px!important;}');
                        mywindow.document.write(
                            '.slip-heading {background-color: #e5e7eb;margin-top:60px!important;padding: 10px;border: 2px solid lightgray;}'
                        );
                        mywindow.document.write('.slip-body {margin: 26px;padding: 10px;}');
                        mywindow.document.write('.font-email {font-size:13px!important;}');
                        mywindow.document.write(
                            '.slip-body-main {margin-top: 32px;border: 2px solid lightgray;padding-left: 10px!important;padding-right: 10px!important; padding-top: 10px !important; height: 43%;}'
                        );
                        mywindow.document.write('</style>');
                        mywindow.document.write('</head><body >');
                        // mywindow.document.write('<h1>' + document.title  + '</h1>');
                        mywindow.document.write(document.getElementById('printSummary').innerHTML);
                        mywindow.document.write('</body></html>');

                        mywindow.document.close(); // necessary for IE >= 10
                        mywindow.focus(); // necessary for IE >= 10*/

                        mywindow.print();
                        mywindow.close();
    }

    const department = computed(() => {
        return getDepartmentListData.value.filter(department => {
        return department.id == getSuccessResponse.value.department_id;
    })
    })
</script>