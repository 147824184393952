<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-7">
        <!-- <button class="btn btn-primary btn-lg appointment-btn">
          Make an appointment
        </button> -->
        <div class="landing-intro">
          <h1>Schedule Appointment for your Doctor Online</h1>
          <p>
            Wherever you are, making an appointment has never been easier. Find
            your doctor, select desired time and schedule appointment, all from
            the comforts of your home or office.
          </p>
          <p>
            Can't travel to hospital? Just use our Tele Consultation facility.
            Consult with your preferred doctor over Video Conference.
          </p>
          <p>Make an appointment and pay for it online.</p>

          <div class="payment-image-section mt-5">
            <!-- <img
              class="img-fluid payment-imgs"
              src="../assets/images/we_accept.png"
              alt="we_accept"
            /> -->
            <router-link :to="{ name: 'page.appointment' }" class="widget-header me-4 text-white btn bg-primary">
              <button class="btn btn-primary btn-lg appointment-btn">
                Make An Appointment
              </button>
          </router-link>
          </div>
          <div class="card mt-3" style="background-color: var(--foundation----secondary---s0); font-size: 16px;">
                            <div class="px-3 py-3" style="background-color: #fff; border-radius: 0.375rem 0.375rem 0 0;">
                            <span  style="color: var(--foundation----secondary---s400); font-weight: 600;">Note: </span>
                        </div>
                        <div class="px-3 py-3">
                            
                            <span  style="color: var(--foundation----secondary---s400); line-height: 1;"> 
                              
                                Your username is your phone number and your password is your last name (all capital).
                                <br>
                                
                                तपाईको प्रयोगकर्ता नाम तपाईको फोन नम्बर हो र तपाईको पासवर्ड तपाईको थर (सबै अङ्ग्रेजी टुलो अक्षर) हो । 
                                <br>
                           </span>

                        </div>
                        </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="landing-img" >
          <img
            class="img-fluid intro-img"
            src="../assets/images/mobileimgnew.png"
            alt="mobileimg"
          />
          <br>
          <!-- <div class="download-links"> -->
            <!-- <a target="_blank" v-for="(link,index) in getDownloadAppLink" :key="index" :href="link.link"><img :src="getImg(link.image)" class="download_link_image" alt="image"></a> -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import {useCommonStore} from '@/core/modules/common'
import { storeToRefs } from 'pinia'
const {getDownloadAppLink} = storeToRefs(useCommonStore())
const manageCommonStore = useCommonStore()
onMounted(()=>{
    manageCommonStore.fetchStoreLink();

    
let opd_id = window.localStorage.getItem('opd_id');
if(opd_id) {
    window.localStorage.removeItem('opd_id')
}
let schedule_id = window.localStorage.getItem('schedule_id');
if(schedule_id) {
    window.localStorage.removeItem('schedule_id')
}
let rate = window.localStorage.getItem('rate');
if(rate) {
  window.localStorage.removeItem('rate')
}
let bookpay = window.localStorage.getItem('bookpay');
if(bookpay) {
    window.localStorage.removeItem('bookpay')
}
})

function getImg(pic){
  return require('../assets/images/'+pic)
}


</script>
